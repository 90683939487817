import { computed, defineComponent, inject, ref } from '@vue/composition-api';
import { BookingStatus } from '@/generated-types/graphql.types';
import { isAfterBookBeforeMinimumThresholdMonthView, isBeforeBookBeforeMaximumThresholdMonthView } from '@/util/dates';
import moment from 'moment';
export default defineComponent({
    props: {
        data: {
            type: Array,
            default: () => []
        },
        getSelectedDay: {
            type: Function,
            default: () => { }
        },
        titleArray: {
            type: Array,
            default: () => []
        },
        size: {
            type: String,
            default: 'normal',
            validate: val => val === 'normal' || val === 'small'
        },
        spacesCalendarData: {
            type: Array,
            default: () => []
        },
        allVenueSpaces: {
            type: Array,
            default: () => []
        }
    },
    setup(props, context) {
        const { emit } = context;
        // Props drilling from AvailabilityManagement.vue, CalendarBlock.vue or VendorDashboardProvider.vue
        const isCalendarInGuestMode = inject('isCalendarInGuestMode');
        const getDayLimits = (fullDate) => {
            let day = props.spacesCalendarData.find(day => day.day === fullDate);
            if (!day) {
                const sameWeekday = moment(fullDate)
                    .subtract(7, 'day')
                    .format('YYYY-MM-DD');
                day = props.spacesCalendarData.find(day => day.day === sameWeekday);
            }
            const dayBookings = day?.space_bookings || [];
            const dayAvailability = day?.space_availability || [];
            const hasActiveBookings = dayBookings.length
                ? dayBookings.filter(booking => [
                    BookingStatus.Pending,
                    BookingStatus.PaymentProcessing,
                    BookingStatus.Paid
                ].includes(booking?.booking_status)).length > 0
                : false;
            const isFullyClosed = day?.is_day_fully_closed || false;
            const isInAnotherMonth = day?.is_in_another_month || false;
            const isInCurrentWeek = day?.is_in_current_week || false;
            const isNotWorkingDay = !day?.is_venue_working_day || false;
            const isPartiallyClosed = (day?.is_day_partially_closed && day?.is_venue_working_day) ||
                (dayAvailability.filter(slot => slot.is_opened)?.length > 0 &&
                    !day?.is_venue_working_day) ||
                false;
            const isBookingLimitReached = !isCalendarInGuestMode.value || !dayBookings.length
                ? false
                : dayBookings.length >= dayBookings[0].booking_limit;
            const isBeforeBookingMinThreshold = !isCalendarInGuestMode.value
                ? false
                : !isAfterBookBeforeMinimumThresholdMonthView(fullDate, props.allVenueSpaces[0].book_before_min_th);
            const isBeforeBookingMaxThreshold = !isCalendarInGuestMode.value
                ? true
                : isBeforeBookBeforeMaximumThresholdMonthView(fullDate, props.allVenueSpaces[0].book_before_max_th);
            const isToday = day?.is_today || false;
            return {
                hasActiveBookings,
                isFullyClosed,
                isInAnotherMonth,
                isInCurrentWeek,
                isNotWorkingDay,
                isPartiallyClosed,
                isToday,
                isBookingLimitReached,
                isBeforeBookingMinThreshold,
                isBeforeBookingMaxThreshold
            };
        };
        const dayLimits = computed(() => {
            return props.data.reduce((acc, row) => {
                row.forEach((col) => {
                    if (col) {
                        acc[col.date.full] = getDayLimits(col.date.full);
                    }
                });
                return acc;
            }, {});
        });
        const onMonthDayClick = () => {
            // When a day is clicked in a Month View, the calendar mode for the space page must be set to `week`
            // See parent `we-calendar.vue`
            emit('month-day-clicked');
        };
        const activeWeekRowIndex = ref(0);
        const toggleActiveRow = (rowIndex) => {
            activeWeekRowIndex.value = rowIndex;
        };
        return {
            activeWeekRowIndex,
            dayLimits,
            isCalendarInGuestMode,
            getDayLimits,
            onMonthDayClick,
            toggleActiveRow
        };
    }
});
