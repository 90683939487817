import { computed, defineComponent, inject, onMounted, reactive, watch, ref } from '@vue/composition-api';
import DayWeekView from '@/Calendar/components/DayWeekView.vue';
import EventBus from '@/shared/services/eventBus';
import FormSelect from '@/shared/components/form/FormSelect.vue';
import { map } from 'lodash';
import { dateString, getWeekDay, stringToSlot, unixFormatted, dayPickerFilterDays, isPastSlot, isAfterBookBeforeMinimumThreshold, isBeforeBookBeforeMaximumThreshold } from '@/util/dates';
import { reFormatTime } from '@/util/utils';
export default defineComponent({
    name: 'WeekView',
    components: {
        DayWeekView,
        FormSelect
    },
    props: {
        data: {
            type: Array,
            default: () => []
        },
        spacesCalendarData: {
            type: Array,
            default: () => []
        },
        allVenueSpaces: {
            type: Array,
            default: () => []
        },
        slots: {
            type: Object,
            default: () => { }
        },
        getSelectedDay: {
            type: Function,
            default: () => { }
        },
        weekTimeline: {
            type: Object,
            default: () => { }
        },
        workingTime: {
            type: Array,
            default: () => []
        },
        selectedDay: {
            type: String,
            default: ''
        },
        labels: {
            type: Array,
            default: () => []
        },
        wtMode: {
            type: Boolean,
            default: false
        }
    },
    setup(props, context) {
        const { root } = context;
        const spaceIds = computed(() => props.allVenueSpaces.map(space => space.space_id));
        // Props drilling from AvailabilityManagement.vue, CalendarBlock.vue or VendorDashboardProvider.vue
        const isCalendarInGuestMode = inject('isCalendarInGuestMode');
        const thresholdMin = ref(props.allVenueSpaces[0]?.book_before_min_th);
        const thresholdMax = ref(props.allVenueSpaces[0]?.book_before_max_th);
        const week = reactive({
            day: props.selectedDay,
            time: {
                from: 0,
                to: 0
            },
            isTablet: false
        });
        /**
         * Reactive variable that contains the availability slots retrieved from the server.
         */
        const availability = computed(() => {
            return props.spacesCalendarData
                .filter(day => day.space_availability && day.space_availability.length > 0)
                .map(day => day.space_availability)
                .flat();
        });
        /**
         * Reactive variable that contains the bookings retrieved from the server.
         */
        const bookings = computed(() => {
            return props.spacesCalendarData
                .filter(day => day.space_bookings && day.space_bookings.length > 0)
                .map(day => day.space_bookings)
                .flat();
        });
        /**
         * Reactive variable that contains the mapping of dates to be shown in the calendar and the
         * availability and bookings for each date.
         */
        const dates = computed(() => {
            return props.data[0].map(date => {
                const foundAvailability = availability.value.filter(slot => {
                    const slotDateAsString = slot?.slot_from?.substring(0, slot?.slot_from.indexOf(' '));
                    return date.date.full === slotDateAsString;
                });
                const foundBookings = bookings.value.filter(booking => {
                    const bookingDateAsString = booking?.slot_start?.substring(0, booking?.slot_start.indexOf(' '));
                    return date.date.full === bookingDateAsString;
                });
                return {
                    ...date,
                    availability: foundAvailability,
                    bookings: foundBookings
                };
            });
        });
        /**
         * Reactive variable that contains the working hours for the selected day.
         */
        const weekDayHours = computed(() => props.workingTime.find(day => day.week_day === getWeekDay(week.day)));
        /**
         * Reactive variable that contains the labels to be shown in the booking start time dropdown.
         * The labels are filtered to show only the labels that are not closed for the selected day.
         * The label is closed if:
         * - The label is in the closed_labels array of the availability slots
         * - The label is in the closed_labels array of the bookings
         * - The label is greater than the selected end label
         * - The label is lower than the location opening time
         * - The label is higher or equals the location closing time
         *
         * If isCalendarInGuestMode is false, then all labels are available (for vendor dashboard calendar).
         */
        const labelsFrom = computed(() => {
            if (!week.day) {
                return [];
            }
            return props.labels.map((label) => {
                const date = dates.value.find((date) => date.date.full === week.day);
                let isLabelClosed = false;
                date?.availability.filter((slot) => {
                    if (slot.is_opened)
                        return;
                    if (slot.closed_labels.slice(0, -1).includes(label.value) &&
                        isCalendarInGuestMode.value) {
                        isLabelClosed = true;
                    }
                });
                date?.bookings.filter((booking) => {
                    if (['DRAFT', 'EXPIRED', 'DECLINED', 'CANCELLED'].includes(booking.booking_status))
                        return;
                    if (booking.closed_labels.slice(0, -1).includes(label.value)) {
                        isLabelClosed = true;
                    }
                });
                return {
                    ...label,
                    disabled: isCalendarInGuestMode.value
                        ? isLabelClosed ||
                            (week.time.to > 0 && label.value >= week.time.to) ||
                            label.value < weekDayHours?.value?.open_time ||
                            label.value >= weekDayHours?.value?.close_time ||
                            (date &&
                                date.isToday &&
                                isPastSlot(label.value, week.day.toString())) ||
                            !isAfterBookBeforeMinimumThreshold(`${week.day} ${label.label}`, thresholdMin.value) ||
                            !isBeforeBookBeforeMaximumThreshold(`${week.day} ${label.label}`, thresholdMax.value)
                        : (date &&
                            date.isToday &&
                            isPastSlot(label.value, week.day.toString())) ||
                            (week.time.to > 0 && label.value >= week.time.to)
                };
            });
        });
        /**
         * Reactive variable that contains the labels to be shown in the booking end time dropdown.
         * The labels are filtered to show only the labels that are not closed for the selected day.
         * The label is closed if:
         * - The label is in the closed_labels array of the availability slots
         * - The label is in the closed_labels array of the bookings
         * - The label is lower or equals the selected start label
         * - The label is lower or equals the location opening time
         * - The label is higher than the location closing time
         *
         * If isCalendarInGuestMode is false, then all labels are available (for vendor dashboard calendar).
         */
        const labelsTo = computed(() => {
            if (!week.day) {
                return [];
            }
            return props.labels.map((label) => {
                const date = dates.value.find((date) => date.date.full === week.day);
                let isLabelClosed = false;
                date?.availability.filter((slot) => {
                    if (slot.is_opened)
                        return;
                    if (slot.closed_labels.slice(1).includes(label.value)) {
                        isLabelClosed = true;
                    }
                });
                date?.bookings.filter((booking) => {
                    if (['DRAFT', 'EXPIRED', 'DECLINED', 'CANCELLED'].includes(booking.booking_status))
                        return;
                    if (booking.closed_labels.slice(1).includes(label.value)) {
                        isLabelClosed = true;
                    }
                });
                return {
                    ...label,
                    disabled: isCalendarInGuestMode.value
                        ? isLabelClosed ||
                            (week.time.from > 0 && label.value <= week.time.from) ||
                            label.value <= weekDayHours?.value?.open_time ||
                            label.value > weekDayHours?.value?.close_time ||
                            (date &&
                                date.isToday &&
                                isPastSlot(label.value, week.day.toString())) ||
                            !isAfterBookBeforeMinimumThreshold(`${week.day} ${label.label}`, thresholdMin.value + 0.5) ||
                            !isBeforeBookBeforeMaximumThreshold(`${week.day} ${label.label}`, thresholdMax.value + 0.5)
                        : (date &&
                            date.isToday &&
                            isPastSlot(label.value, week.day.toString())) ||
                            (week.time.from > 0 && label.value <= week.time.from)
                };
            });
        });
        /**
         * Reactive variable that contains the labels to be shown in the calendar header.
         * The labels are filtered to show only odd hours (09:00, 11:00, ...).
         */
        const filteredLabels = computed(() => props.labels.filter((_, i) => (i + 1) % 2 !== 0));
        /**
         * Reactive variable that contains the options for the day picker.
         * The day option is marked as disabled if:
         * - The day is in the past (dayPickerFilterDays is false)
         * - The day is not a working day (is_venue_working_day is false)
         * - The day is fully closed (is_day_fully_closed is true)
         *
         * If isCalendarInGuestMode is false, then all days are available (for vendor dashboard calendar).
         */
        const dayPickerOptions = computed(() => {
            return map(props.data[0].filter(d => d.date.full), el => {
                const closeTime = props.workingTime.find(wt => wt.week_day === el.date.day)?.close_time;
                return {
                    label: el.date.weekModeTitle,
                    value: el.date.full,
                    disabled: isCalendarInGuestMode.value
                        ? !dayPickerFilterDays(el.date.full) ||
                            !props.spacesCalendarData.find((day) => day.day === el.date.full)?.is_venue_working_day ||
                            props.spacesCalendarData.find((day) => day.day === el.date.full)?.is_day_fully_closed ||
                            !isAfterBookBeforeMinimumThreshold(`${el.date.full} ${reFormatTime(closeTime)}`, thresholdMin.value) ||
                            !isBeforeBookBeforeMaximumThreshold(`${el.date.full} ${props.labels[1].label}`, thresholdMax.value)
                        : !dayPickerFilterDays(el.date.full)
                };
            });
        });
        const handleResize = () => {
            week.isTablet = root.$mq.tablet;
        };
        const updateStartTime = (time) => {
            if (!week.time.to) {
                EventBus.$emit('updateBookingEndTime', time);
            }
            EventBus.$emit('updateBookingStartTime', time);
        };
        const updateEndTime = (time) => {
            if (!week.time.from && weekDayHours.value) {
                week.time.from = weekDayHours.value.open_time;
                EventBus.$emit('updateBookingStartTime', week.time.from);
            }
            EventBus.$emit('updateBookingEndTime', time);
        };
        const selectedTimeSlots = (arr) => {
            const slotTo = Number(arr[arr.length - 1]);
            week.time = {
                from: Number(arr[0]),
                to: slotTo + 30
            };
            updateStartTime(Number(arr[0]));
            updateEndTime(slotTo + 30);
        };
        const onDateChange = (selectedDay) => {
            globalThis.$store.dispatch('$_calendar/setSelectedCalendarDay', selectedDay);
            props.getSelectedDay(selectedDay);
            week.day = selectedDay;
        };
        watch(() => props.slots, () => {
            const from = props.slots.slotFrom;
            const to = props.slots.slotTo;
            if (from && to && from !== to) {
                week.time.from = stringToSlot(dateString(from, globalThis.$timezone));
                week.time.to = stringToSlot(dateString(to, globalThis.$timezone));
                week.day = unixFormatted(from, 'YYYY-MM-DD');
            }
        }, { immediate: true });
        watch(() => props.data[0], // Days shown in the day view calendar
        (newVal, oldVal) => {
            if (newVal && oldVal) {
                const newStartDay = newVal[0].date;
                const oldStartDay = oldVal[0].date;
                const newEndDay = newVal[newVal.length - 1].date;
                const oldEndDay = oldVal[oldVal.length - 1].date;
                if (newStartDay.full === oldStartDay.full)
                    return;
                const isMonthChangedOnWeekView = (newEndDay.month !== oldEndDay.month ||
                    newEndDay.year !== oldEndDay.year) &&
                    newEndDay.date < oldEndDay.date &&
                    newStartDay.date > oldStartDay.date;
                const hasAnyChangesInCalendarHeaderSelection = newEndDay.month !== oldEndDay.month ||
                    newEndDay.year !== oldEndDay.year;
                if (isMonthChangedOnWeekView ||
                    hasAnyChangesInCalendarHeaderSelection) {
                    // Re-fetch calendar data when next month or year is selected
                    EventBus.$emit('spacesAvailabilityUpdated', {
                        month: newEndDay.month,
                        year: newEndDay.year
                    });
                }
                if ((newStartDay.month !== oldStartDay.month ||
                    newStartDay.year !== oldStartDay.year) &&
                    newStartDay.date > oldStartDay.date &&
                    newEndDay.date < oldEndDay.date) {
                    // Re-fetch calendar data when previous month or year is selected
                    EventBus.$emit('spacesAvailabilityUpdated', {
                        month: newStartDay.month,
                        year: newStartDay.year
                    });
                }
            }
        }, { immediate: true });
        onMounted(() => {
            const from = props.slots.slotFrom;
            const to = props.slots.slotTo;
            if (!props.slots || !from || !to) {
                return;
            }
            updateStartTime(stringToSlot(dateString(from, globalThis.$timezone)));
            updateEndTime(stringToSlot(dateString(to, globalThis.$timezone)));
        });
        const cellWidth = ref(0);
        return {
            cellWidth,
            dates,
            dayPickerOptions,
            filteredLabels,
            isCalendarInGuestMode,
            labelsFrom,
            labelsTo,
            spaceIds,
            week,
            handleResize,
            onDateChange,
            selectedTimeSlots,
            updateEndTime,
            updateStartTime
        };
    }
});
