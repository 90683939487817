var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"we-calendar-body-grid"},[_c('div',{class:"we-calendar-week"},_vm._l((_vm.titleArray),function(item){return _c('div',{key:item,class:"we-calendar-week__item"},[_vm._v(" "+_vm._s(item)+" ")])}),0),_vm._l((_vm.data),function(row,index){return _c('div',{key:index,class:[
      'we-calendar-body-row',
      {
        selected: _vm.activeWeekRowIndex === index && !_vm.isCalendarInGuestMode
      }
    ],on:{"click":function($event){return _vm.toggleActiveRow(index)}}},_vm._l((row),function(col){return _c('div',{key:col.date.full,staticClass:"we-calendar-day-item"},[(col)?_c('div',{class:[
          'we-calendar-item',
          ("" + (_vm.$mq.phone ? 'small' : _vm.size)),
          {
            'is-otherMonth': _vm.dayLimits[col.date.full].isInAnotherMonth,
            'is-today': _vm.dayLimits[col.date.full].isToday,
            'is-selected': col.isSelected,
            'is-disabled':
              (_vm.dayLimits[col.date.full].isFullyClosed ||
                (_vm.dayLimits[col.date.full].isNotWorkingDay &&
                  !_vm.dayLimits[col.date.full].isPartiallyClosed) ||
                _vm.dayLimits[col.date.full].isBookingLimitReached ||
                _vm.dayLimits[col.date.full].isBeforeBookingMinThreshold ||
                !_vm.dayLimits[col.date.full].isBeforeBookingMaxThreshold) &&
              !col.isSelected,
            'is-inCurrentWeek': _vm.dayLimits[col.date.full].isInCurrentWeek,
            'is-partial':
              !_vm.dayLimits[col.date.full].isFullyClosed &&
              _vm.dayLimits[col.date.full].isPartiallyClosed &&
              !col.isSelected &&
              !_vm.dayLimits[col.date.full].isBeforeBookingMinThreshold &&
              _vm.dayLimits[col.date.full].isBeforeBookingMaxThreshold
          }
        ],on:{"click":function($event){_vm.getSelectedDay(col.date);
          _vm.onMonthDayClick();}}},[_c('span',[_vm._v(_vm._s(col.date.date))])]):_vm._e(),(_vm.dayLimits[col.date.full].hasActiveBookings)?_c('div',{class:[
          'we-dot',
          {
            'is-currentWeek': _vm.dayLimits[col.date.full].isInCurrentWeek,
            'is-selected': col.isSelected,
            'is-inCurrentWeek':
              _vm.dayLimits[col.date.full].isInCurrentWeek && !col.isSelected
          }
        ]}):_vm._e()])}),0)})],2)}
var staticRenderFns = []

export { render, staticRenderFns }