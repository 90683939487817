import { computed, defineComponent, ref, watch } from '@vue/composition-api';
import { map } from 'lodash';
import { momentObj } from '@/util/dates';
import vClickOutside from 'v-click-outside';
//TODO: POPUP FOR MONTH AND WEEK CHANGE
export default defineComponent({
    name: 'Popup',
    directives: {
        clickOutside: vClickOutside.directives
    },
    props: {
        info: { type: Object, default: () => { } },
        currentDate: { type: String, default: '' }
    },
    setup(props, context) {
        const { emit } = context;
        const visible = ref(false);
        const month = ref(0);
        const year = ref(0);
        const months = computed(() => {
            return map(globalThis.$moment.monthsShort('-MMM-'), (month, index) => {
                return {
                    name: month,
                    value: Number(index) + 1,
                    isCurMonth: month.value === index
                };
            });
        });
        const closePopup = () => {
            visible.value = false;
        };
        const incrementYear = () => {
            year.value += 1;
        };
        const decrementYear = () => {
            year.value -= 1;
        };
        const showPopup = () => {
            visible.value = !visible.value;
        };
        const selectMonth = (_month) => {
            month.value = _month;
            visible.value = false;
            emit('popup-change-month', `${year.value}-${month.value}-01`);
        };
        watch(() => props.currentDate, () => {
            const formattedDate = momentObj(props.currentDate);
            year.value = formattedDate.year();
            month.value = formattedDate.month();
        }, { immediate: true });
        return {
            month,
            months,
            visible,
            year,
            closePopup,
            decrementYear,
            incrementYear,
            selectMonth,
            showPopup
        };
    }
});
