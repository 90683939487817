var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.$root.$mq.phone)?_c('div',{directives:[{name:"we-resize",rawName:"v-we-resize",value:(_vm.handleResize),expression:"handleResize"}],staticClass:"we-calendar-body-week we-calendar-desktop"},[_c('div',{staticClass:"we-calendar-week-item day-wrapper no-border"},[(_vm.isCalendarInGuestMode)?_c('div',{staticClass:"day-name-labels"}):_c('div',{staticClass:"day-name-labels-availability",style:({ width: _vm.spaceIds.length > 1 ? '180px' : '120px' })}),_c('div',{staticClass:"labels-grid",style:({
          width: ("calc(100% - " + (_vm.isCalendarInGuestMode ? '120px' : '180px') + ")")
        })},[_c('div',{staticClass:"cells"},_vm._l((_vm.filteredLabels.slice(
              0,
              _vm.filteredLabels.length
            )),function(item,index){return _c('div',{key:item.label,class:[
              'cell no-border',
              {
                hidden:
                  (index + 1) % 2 === 0 && index !== _vm.filteredLabels.length - 1
              }
            ],style:({
              left: ((_vm.cellWidth * index * 2 + index - 2) + "px"),
              width: ((item.width) + "px"),
              transform: ("translateX(" + (_vm.filteredLabels[_vm.filteredLabels.length - 1].label.slice(
                  0,
                  2
                ) ===
                  _vm.filteredLabels[_vm.filteredLabels.length - 2].label.slice(
                    0,
                    2
                  ) && index === _vm.filteredLabels.length - 1
                  ? '-6px'
                  : '0') + ")")
            })},[_vm._v(" "+_vm._s(item.label)+" ")])}),0)])]),_vm._l((_vm.dates),function(item,index){return _c('div',{key:("week-item-" + index),staticClass:"we-calendar-week-item"},[_c('DayWeekView',{attrs:{"item":item,"schedule":_vm.weekTimeline,"date":_vm.week,"all-venue-spaces":_vm.allVenueSpaces,"wt-mode":_vm.wtMode,"working-time":_vm.workingTime},on:{"change":_vm.onDateChange,"selected":_vm.selectedTimeSlots,"onResize":function($event){_vm.cellWidth = $event}}})],1)})],2):_vm._e(),_c('div',{staticClass:"we-calendar-week-controls"},[_c('FormSelect',{staticClass:"picker-day",attrs:{"data-gtm":"space_calendar_day_selector","options":_vm.dayPickerOptions,"placeholder":_vm.$t('customer.space.date_time.day')},on:{"input":_vm.onDateChange},model:{value:(_vm.week.day),callback:function ($$v) {_vm.$set(_vm.week, "day", $$v)},expression:"week.day"}}),_c('FormSelect',{staticClass:"picker-time",attrs:{"data-gtm":"space_calendar_time_from_selector","options":_vm.labelsFrom,"placeholder":_vm.$t('customer.space.date_time.time')},on:{"input":_vm.updateStartTime},model:{value:(_vm.week.time.from),callback:function ($$v) {_vm.$set(_vm.week.time, "from", $$v)},expression:"week.time.from"}}),_c('FormSelect',{staticClass:"picker-time",attrs:{"data-gtm":"space_calendar_time_to_selector","options":_vm.labelsTo,"placeholder":_vm.$t('customer.space.date_time.time')},on:{"input":_vm.updateEndTime},model:{value:(_vm.week.time.to),callback:function ($$v) {_vm.$set(_vm.week.time, "to", $$v)},expression:"week.time.to"}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }