var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"availability-day-wrapper",on:{"mouseover":function($event){return _vm.updateVerticalLineDisplay('block')},"mouseleave":function($event){return _vm.updateVerticalLineDisplay('none')}}},[_c('div',{class:[
      'day-name',
      {
        'is-selected': _vm.item.isSelected
      }
    ]},[_c('span',[_vm._v(_vm._s(_vm.item.date.weekModeTitle))])]),(!_vm.$mq.phone && _vm.allVenueSpaces && _vm.allVenueSpaces.length > 1)?_c('div',{class:[
      'spaces-ids',
      {
        'is-selected': _vm.item.isSelected
      }
    ]},_vm._l((_vm.allVenueSpaces),function(_space,index){return _c('div',{key:index,staticClass:"space-part",style:({ 'min-height': _vm.allVenueSpaces.length === 1 ? '34px' : '24px' })},[_vm._v(" #"+_vm._s(_space.space_reference)+" "),(_space.internal_name)?_c('span',{staticClass:"space-name"},[_vm._v(_vm._s(_space.internal_name))]):_vm._e()])}),0):_vm._e(),_c('div',{ref:"dayGrid",staticClass:"day-grid",on:{"mouseup":_vm.onDragEnd,"mouseleave":_vm.onDragEnd}},[_c('DragSelect',{staticClass:"cells",attrs:{"attribute":"labelValue"},on:{"change":_vm.dragSelected}},_vm._l((_vm.schedule.labels.slice(
          0,
          _vm.schedule.labels.length - 1
        )),function(d,index){return _c('div',{key:d.value,class:['cell', _vm.getCellClass(d.value)],style:({ width: d.width }),attrs:{"labelValue":d.value},on:{"mouseover":function($event){return _vm.onMouseEnterCalendarCell(d)},"mouseleave":_vm.onMouseLeaveCalendarCell}},_vm._l((_vm.allVenueSpaces),function(s,i){return _c('div',{key:i,class:[
            'space-part',
            {
              closed:
                _vm.pastSlots[index] ||
                _vm.bookBeforeMinSlots[index] ||
                !_vm.bookBeforeMaxSlots[index],
              disabled:
                (!_vm.pastSlots[index] &&
                  ((_vm.availabilitySlots[s.space_id]
                    ? _vm.availabilitySlots[s.space_id][index].closed
                    : false) ||
                    (!_vm.venueIsOpened(d.value) &&
                      (_vm.availabilitySlots[s.space_id]
                        ? !_vm.availabilitySlots[s.space_id][index].opened
                        : true)))) ||
                (_vm.isCalendarInGuestMode &&
                  (_vm.bookingsSlots[s.space_id]
                    ? _vm.bookingsSlots[s.space_id][index].closed
                    : false)) ||
                _vm.isBookingLimitReached
            }
          ],style:({
            'min-height': _vm.allVenueSpaces.length === 1 ? '34px' : '24px'
          })})}),0)}),0),(_vm.item.isDisabled)?_c('div',{staticClass:"venue-closed"}):_vm._e(),_c('div',{ref:"verticalLine",staticClass:"vertical-line"},[_c('span',{staticClass:"vertical-line__text"},[_vm._v(_vm._s(_vm.verticalLineText))]),_c('div',{staticClass:"vertical-line__line"})])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }