import { computed, defineComponent, inject, onMounted, onUnmounted, onUpdated, reactive, ref, watch } from '@vue/composition-api';
import DragSelect from 'drag-select-vue';
import { isPastSlot, slotIsClosed, isAfterBookBeforeMinimumThreshold, isBeforeBookBeforeMaximumThreshold } from '@/util/dates';
import { groupBy, forOwn } from 'lodash';
import { BookingStatus } from '@/generated-types/graphql.types';
export default defineComponent({
    components: {
        DragSelect
    },
    props: {
        item: {
            type: Object,
            default: () => { }
        },
        schedule: {
            type: Object,
            default: () => ({})
        },
        workingTime: {
            type: Array,
            default: () => []
        },
        date: {
            type: Object,
            default: () => { }
        },
        wtMode: {
            type: Boolean,
            default: false
        },
        allVenueSpaces: {
            type: Array,
            default: () => []
        }
    },
    setup(props, context) {
        const { emit } = context;
        // Props drilling from AvailabilityManagement.vue, CalendarBlock.vue or VendorDashboardProvider.vue
        const isCalendarInGuestMode = inject('isCalendarInGuestMode');
        const range = (start, end) => {
            return Array(end - start + 1)
                .fill('')
                .map((_, idx) => String((start + idx) * 30));
        };
        const selectedSlots = ref(props.date.time.from
            ? range(props.date.time.from / 30, props.date.time.to / 30)
            : []);
        // Availability grouped by space id
        const availabilityBySpaceId = computed(() => groupBy(props.item.availability, item => item?.space_id));
        // Bookings grouped by space id
        const bookingsBySpaceId = computed(() => groupBy(props.item.bookings, item => item?.space_id));
        // Venue working time
        const venueWorkingTime = computed(() => props.workingTime.find(el => el.week_day === props.item.date.day));
        // Unavailable past slots
        const pastSlots = computed(() => {
            return props.schedule.labels.map(el => isPastSlot(el.value, props.item.date.full));
        });
        // Slots that are disabled because of the BOOK_BEFORE_MIN business rule (only for guest mode)
        const bookBeforeMinSlots = computed(() => {
            const threshold = props.allVenueSpaces[0].book_before_min_th;
            return props.schedule.labels.map(el => isCalendarInGuestMode.value
                ? !isAfterBookBeforeMinimumThreshold(`${props.item.date.full} ${el.label}`, threshold)
                : false);
        });
        // Slots that are disabled because of the BOOK_BEFORE_MAX business rule (only for guest mode)
        const bookBeforeMaxSlots = computed(() => {
            const threshold = props.allVenueSpaces[0].book_before_max_th;
            return props.schedule.labels.map(el => isCalendarInGuestMode.value
                ? isBeforeBookBeforeMaximumThreshold(`${props.item.date.full} ${el.label}`, threshold)
                : true);
        });
        const checkLabelAvailabilityStatus = (spaceId, label, isOpened) => {
            const spaceAvailability = availabilityBySpaceId.value[spaceId];
            if (!spaceAvailability || !spaceAvailability.length)
                return false;
            const availability = spaceAvailability.filter(slot => {
                const slotDateAsString = slot?.slot_from?.substring(0, slot?.slot_from.indexOf(' '));
                return (slot.is_opened === isOpened &&
                    props.item.date.full === slotDateAsString);
            });
            if (!availability || !availability.length)
                return false;
            const isLabelInAvailability = availability.filter(slot => 
            // Exclude upper boundary
            slot.closed_labels?.slice(0, -1).includes(label));
            return !!isLabelInAvailability.length || false;
        };
        // Availability slots grouped by closed and opened property
        const availabilitySlots = computed(() => {
            const res = {};
            forOwn(availabilityBySpaceId.value, (_, spaceId) => {
                res[spaceId] = props.schedule.labels.map(label => {
                    return {
                        label: label,
                        closed: checkLabelAvailabilityStatus(Number(spaceId), label.value, false),
                        opened: checkLabelAvailabilityStatus(Number(spaceId), label.value, true)
                    };
                });
            });
            return res;
        });
        const checkLabelBookingStatus = (spaceId, label) => {
            const spaceBookings = bookingsBySpaceId.value[spaceId];
            if (!spaceBookings || !spaceBookings.length)
                return false;
            const bookings = spaceBookings.filter(booking => {
                const bookingDateAsString = booking?.slot_start?.substring(0, booking?.slot_start.indexOf(' '));
                const allowedStatuses = [
                    BookingStatus.Paid,
                    BookingStatus.Pending,
                    BookingStatus.PaymentProcessing
                ];
                return (props.item.date.full === bookingDateAsString &&
                    allowedStatuses.includes(booking.booking_status));
            });
            if (!bookings || !bookings.length)
                return false;
            const isLabelBooked = bookings.filter(booking => 
            // Exclude upper boundary
            booking.closed_labels?.slice(0, -1).includes(label));
            return !!isLabelBooked.length || false;
        };
        // Booked slots grouped by closed property
        const bookingsSlots = computed(() => {
            const res = {};
            forOwn(bookingsBySpaceId.value, (_, spaceId) => {
                res[spaceId] = props.schedule.labels.map(label => {
                    return {
                        label: label,
                        closed: checkLabelBookingStatus(Number(spaceId), label.value),
                        opened: false
                    };
                });
            });
            return res;
        });
        watch(availabilityBySpaceId, val => {
            if (val && !isCalendarInGuestMode.value) {
                // Clear selected slots after new availability is created
                selectedSlots.value = [];
            }
        }, { immediate: true });
        watch(() => [props.date.time.from, props.date.time.to], ([from, to]) => {
            if (from < to && props.date.day === props.item.date.full) {
                selectedSlots.value = range(from / 30, to / 30);
                selectedSlots.value.pop();
            }
            else {
                selectedSlots.value = [];
            }
        }, { immediate: true });
        // Check if the venue is opened at current time slot
        const venueIsOpened = (slot) => {
            return ((venueWorkingTime.value &&
                venueWorkingTime.value?.open_time <= slot &&
                slot < venueWorkingTime.value?.close_time) ||
                false);
        };
        /**
         * Adding selected class names for slot when selecting time range
         * @param slot
         */
        const getCellClass = (slot) => {
            let normalizedSlot = typeof slot === 'number' ? slot.toString() : slot;
            const classes = [];
            if (selectedSlots.value.includes(normalizedSlot))
                classes.push('cell-selected');
            if (selectedSlots.value[0] === normalizedSlot)
                classes.push('cell-first');
            if (selectedSlots.value[selectedSlots.value.length - 1] === normalizedSlot)
                classes.push('cell-last');
            return classes;
        };
        const cellSizeWidth = ref(null);
        const handleCellSizeWidth = () => {
            const spacePartElements = document.getElementsByClassName('space-part');
            if (!spacePartElements.length)
                return;
            cellSizeWidth.value = document
                .getElementsByClassName('space-part')[10]
                .getBoundingClientRect().width;
        };
        onMounted(() => {
            handleCellSizeWidth();
            emit('onResize', cellSizeWidth.value);
            window.addEventListener('resize', handleCellSizeWidth);
            selectedSlots.value.forEach(el => getCellClass(el));
        });
        onUpdated(() => {
            handleCellSizeWidth();
            window.addEventListener('resize', handleCellSizeWidth);
        });
        onUnmounted(() => {
            window.removeEventListener('resize', handleCellSizeWidth);
        });
        watch(() => cellSizeWidth.value, val => {
            emit('onResize', val);
        });
        /**
          Check if the booking limit for the day is reached. If it is, the day is fully closed (only for the space page /
          guest mode).
          - when the bookings array is empty, the day is not closed as there are no bookings made yet;
          - when the space booking limit is 0, the day is not closed as the space has no booking number limit;
          - only active bookings count (paid, reserved, reserved and waiting for payment);
         **/
        const isBookingLimitReached = computed(() => {
            if (!isCalendarInGuestMode.value || !props.item.bookings?.length)
                return false;
            const bookingLimit = props.item.bookings[0].booking_limit || 0;
            if (bookingLimit === 0)
                return false;
            const activeBookings = props.item.bookings.filter(booking => booking.booking_status === BookingStatus.Paid ||
                booking.booking_status === BookingStatus.Pending ||
                booking.booking_status === BookingStatus.PaymentProcessing);
            return activeBookings.length >= bookingLimit;
        });
        /*
          Unavailable slots for drag-n-drop selection:
          - booked slots;
          - slots closed by the host in the dashboard;
          - not working days;
          - when the day booking limit is reached;
          - when it's too early to book (BOOK_BEFORE_MIN rule);
          - when it's too late to book (BOOK_BEFORE_MAX rule);
         */
        const slotIsUnavailable = (slot) => {
            const isSlotBooked = !!bookingsSlots.value[props.allVenueSpaces[0].space_id]?.find(_slot => String(_slot.label.value) === slot && _slot.closed);
            const isSlotClosedInAvailability = !!availabilitySlots.value[props.allVenueSpaces[0].space_id]?.find(_slot => String(_slot.label.value) === slot && _slot.closed);
            const isVenueClosed = !venueIsOpened(Number(slot));
            const isSlotOpenedInAvailability = !!availabilitySlots.value[props.allVenueSpaces[0].space_id]?.find(_slot => String(_slot.label.value) === slot && _slot.opened);
            const isEarlyToBook = !!props.schedule.labels.find((label, index) => label.value === Number(slot) && bookBeforeMinSlots.value[index]);
            const isLateToBook = !!props.schedule.labels.find((label, index) => label.value === Number(slot) && !bookBeforeMaxSlots.value[index]);
            return (isSlotBooked ||
                isSlotClosedInAvailability ||
                (props.item.isDisabled && !isSlotOpenedInAvailability) ||
                (isVenueClosed && !isSlotOpenedInAvailability) ||
                isBookingLimitReached.value ||
                isLateToBook ||
                isEarlyToBook);
        };
        const clientMove = reactive({ prev: [], curr: [] });
        // Creating an array of selected time slots
        const dragSelected = (arr) => {
            let res;
            if (props.date.day === props.item.date.full &&
                +arr[0] <= props.date.time.to &&
                +arr[0] > props.date.time.from) {
                clientMove.prev = clientMove.curr;
                clientMove.curr = arr;
                const correctedTimeArr = [];
                let start = props.date.time.from;
                const end = +clientMove.prev[0] > +clientMove.curr[0]
                    ? +arr[0]
                    : +arr[arr.length - 1];
                while (start <= end) {
                    correctedTimeArr.push(start.toString());
                    start += 30;
                }
                res = correctedTimeArr;
            }
            else {
                res = arr;
            }
            if (slotIsClosed(props.item.date.full, res[0]))
                return;
            if (isCalendarInGuestMode.value &&
                (slotIsUnavailable(res[0]) || slotIsUnavailable(res[res.length - 1])))
                return;
            selectedSlots.value = res;
        };
        /**
         * Vertical line block
         */
        const dayGrid = ref(undefined);
        const verticalLine = ref(undefined);
        const verticalLineText = ref('');
        /**
         * Handle mouse move event inside the calendar grid.
         * Display vertical line and set its position when the mouse cursor enters the calendar grid.
         * The line position is calculated based on the mouse cursor position minus 5px to prevent mouse click on the
         * line itself instead of the calendar slot.
         * @param e - mouse event
         * @returns void
         */
        const handleMouseMove = (e) => {
            if (!dayGrid.value || !verticalLine.value)
                return;
            const rect = dayGrid.value.getBoundingClientRect();
            const x = e.clientX - rect.left - 5;
            verticalLine.value.style.display = 'block';
            verticalLine.value.style.left = `${x}px`;
        };
        /**
         * Handle mouse enter event on the calendar cell.
         * @param day - calendar cell data
         * Set vertical line text and add mouse move event listener.
         * @returns void
         */
        const onMouseEnterCalendarCell = (day) => {
            verticalLineText.value = day.label;
            document.addEventListener('mousemove', handleMouseMove);
        };
        /**
         * Handle mouse leave event on the calendar cell.
         * Remove mouse move event listener.
         * @returns void
         */
        const onMouseLeaveCalendarCell = () => {
            document.removeEventListener('mousemove', handleMouseMove);
        };
        /**
         * End of vertical line block
         */
        /**
         * Emit selected slots ONLY after the drag-n-drop selection is finished.
         * This prevents emitting the selected slots on every drag-n-drop event.
         */
        const onDragEnd = () => {
            if (!selectedSlots.value.length)
                return;
            emit('change', props.item.date.full);
            emit('selected', selectedSlots.value);
        };
        const updateVerticalLineDisplay = (style) => {
            if (!verticalLine.value)
                return;
            verticalLine.value.style.display = style;
        };
        return {
            availabilitySlots,
            bookBeforeMaxSlots,
            bookBeforeMinSlots,
            bookingsSlots,
            dayGrid,
            isBookingLimitReached,
            isCalendarInGuestMode,
            pastSlots,
            verticalLine,
            verticalLineText,
            dragSelected,
            getCellClass,
            onDragEnd,
            onMouseEnterCalendarCell,
            onMouseLeaveCalendarCell,
            updateVerticalLineDisplay,
            venueIsOpened
        };
    },
    data() {
        return {
            selected: [],
            slotsAvailability: []
        };
    }
});
